import React from "react"
import "./about.css"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getAboutMainImage = graphql`
  {
    fluid: file(relativePath: { eq: "about-main.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`



const About = () => {
  const data = useStaticQuery(getAboutMainImage)
  
  return (
    <div className="first">
      <div className="container py-5">
        <div   className="row">
          <div data-aos="fade-up" data-aos-duration="1000"  className="col-md-6 my-auto my">
            <Img fluid={data.fluid.childImageSharp.fluid} />
          </div>
          <div className="col-md-6 my-auto cont-right">
            <div className="">
              <h2 data-aos="zoom-in-up" data-aos-duration="1000" className="py-4 heading2">
                We are a young company driven by innovative & creative thinking.
              </h2>
              <h5 data-aos="zoom-in-up" data-aos-duration="1000">
                Having technological experience across multiple business
                domains.
              </h5>
            </div>
          </div>
        </div>

        <div className="row my">
          <div className="col-lg-4 col-md-6">
            <div className="single-service">
               <h4>Our Mission </h4>
              <p>
                To Put India On The Forefront Of Technological Innovation{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-service">
              <h4>Our Vision</h4>
              <p>Accelarate India To Technological Transformation{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-service">
              <h4>Our Goal</h4>
              <p>Providing Cutting Edge IT Solutions & Innovative Products To The Market{" "}
              </p>
            </div>
          </div>
        </div>
      
        
        
        
        

        
      </div>
      
      

    </div>
  )
}

export default About
