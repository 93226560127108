import React from 'react'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import './about_strategy.css';


const getAboutStrategyImage = graphql`
  {
    fluid: file(relativePath: { eq: "about-strategy.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const About_strategy = () => {

    const data = useStaticQuery(getAboutStrategyImage);
    
    return (
        <div className="as-first">

        <div className="container py-5">
            <div className="row">
                <div className="col-md-12">
                    <h2 data-aos="fade-up" data-aos-duration="1000" className="text-center as-heading2">Our Strategy</h2>
                    <h4 data-aos="zoom-in-up" data-aos-duration="1000" className="text-center">We follow a step by step process and follow it striclty till the launch.</h4>
                </div>
                </div> 

        </div>

            <div className="container py-5">
    <div className="row">
        <div className="col-md-12">
        <Img fluid={data.fluid.childImageSharp.fluid} />
        </div>
    </div>
</div>
        </div>
    )
}

export default About_strategy
