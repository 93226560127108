import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about/about"
import About_strategy from "../components/about/about_strategy"
import Contact from "../components/contact/contact"
const AboutPage = () => (
  <Layout>
    <SEO title="About | Transforming Your Vision Into Reality Through Technology" pathname="/about" description="We are a young company having technological experience across multiple business domains." />
    <About />
    <About_strategy/>
    <Contact/>
  </Layout>
)

export default AboutPage
